// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".src-styles-styles__logo--Nn9bz {\n  width: 14rem;\n  padding-top: 1rem;\n}\n\n.src-styles-styles__lineTop--3qSeA {\n  border-color: rgba(0, 0, 0, 0.1607843137);\n  border-width: 5px;\n}\n\na.src-styles-styles__footerLink--2gqFk {\n  text-decoration: underline;\n  color: #314B57;\n  font-size: 15px;\n}\n\n.src-styles-styles__bodyFooter--O8idj {\n  font-size: 10px;\n  color: #314B57;\n}", "",{"version":3,"sources":["/codebuild/output/src2013515343/src/packages/webapp/src/styles/styles.scss","styles.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,iBAAA;ACDF;;ADGA;EACE,yCAPe;EAQf,iBAAA;ACAF;;ADEA;EACE,0BAAA;EACA,cAAA;EACA,eAAA;ACCF;;ADCA;EACE,eAAA;EACA,cAAA;ACEF","file":"styles.scss","sourcesContent":["$color-line-top: #00000029;\n\n.logo {\n  width: 14rem;\n  padding-top: 1rem;\n}\n.lineTop {\n  border-color: $color-line-top;\n  border-width: 5px;\n}\na.footerLink {\n  text-decoration: underline;\n  color: #314B57;\n  font-size: 15px;\n}\n.bodyFooter {\n  font-size: 10px;\n  color: #314B57;\n}",".logo {\n  width: 14rem;\n  padding-top: 1rem;\n}\n\n.lineTop {\n  border-color: rgba(0, 0, 0, 0.1607843137);\n  border-width: 5px;\n}\n\na.footerLink {\n  text-decoration: underline;\n  color: #314B57;\n  font-size: 15px;\n}\n\n.bodyFooter {\n  font-size: 10px;\n  color: #314B57;\n}"]}]);
// Exports
exports.locals = {
	"logo": "src-styles-styles__logo--Nn9bz",
	"lineTop": "src-styles-styles__lineTop--3qSeA",
	"footerLink": "src-styles-styles__footerLink--2gqFk",
	"bodyFooter": "src-styles-styles__bodyFooter--O8idj"
};
module.exports = exports;
